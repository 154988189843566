import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './utils/App';
import reportWebVitals from './reportWebVitals';
import {StyledEngineProvider, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import darkTheme from "./Components/darkTheme";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
