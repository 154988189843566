import React from 'react';
import BlogPost from "./BlogPost";
import image from '../../../images/AirPhoto.png'

const AerialPhotographyPost = ({ post }) => {
    const content = [
        {
            type: 'paragraph',
            text: 'Компания ООО «НПО «Градиент» занимается аэрофотосъемкой линейных и площадных объектов при помощи беспилотных летательных аппаратов. При съемке данным способом получаются следующие продукты: облака точек, ортофотоплатны, 3D-модели местности, цифровые модели рельефа, топографические карты и планы масштабов от 1:500 и до 1:5000.',
        },
        {
            type: 'paragraph',
            text: 'Выполнение полевых работ и создание топографических планов промплощадки с прилегающими территориями по объекту «Лицензионный участок Гремяченского месторождения калийных солей в Котельниковском районе Волгоградской области.',
        },

        {
            type: 'paragraph',
            text: 'Создание и обновление цифровой картографической основы (ЦКО) масштабов 1:100 000, 1:25 000, 1:5 000 с проведением аэрофотосъемки и созданием ортофотопланов на территорию деятельности ООО "РН - Ставропольнефтегаз".',
        },
    ];

    return (
        <BlogPost
            title="Аэрофотосъёмка"
            content={content}
            imageUrl={image}
            date="April 20, 2023"
        />
    );
};


export default AerialPhotographyPost;
