import React from 'react';
import FullWidthTabs from './TabPanelAdmin'; // import your FullWidthTabs component

function AdminPage() {
    return (
        <div>
            <FullWidthTabs />
        </div>
    );
}

export default AdminPage;
