import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import {Paper} from "@mui/material";
import Box from "@mui/material/Box";

function AboutUsPage() {
    return (
        <Container>
            <Typography variant="h4" mt={4} align='left' gutterBottom>
                О компании
            </Typography>
            <Box sx={{marginY: 3}}>
                <Typography variant="subtitle1" gutterBottom>
                    ООО «НПО «Градиент» является передовой компанией в сфере геодезии и картографии, что подтверждается её многогранной специализацией и глубоким опытом работы. С момента своего основания, мы постоянно следим за последними тенденциями и инновациями в области измерений, что позволяет нам оставаться на переднем крае технологического прогресса. Одним из наших ключевых направлений является выполнение маркшейдерских работ. Наша команда специалистов гарантирует высокую точность и надежность всех произведенных измерений, что особенно важно при планировании и разработке новых промышленных объектов или горнодобывающих работ.
                </Typography>
            </Box>
            <Box sx={{marginY: 3}}>
                <Typography gutterBottom>
                    Аэрофотосъёмка — это ещё одна из наших специализаций. Современные беспилотные летательные аппараты и профессиональное оборудование позволяют нам создавать высококачественные снимки с воздуха. Наконец, наша компания осуществляет автоматический деформационный мониторинг. Этот вид мониторинга необходим для наблюдения за состоянием различных сооружений, предупреждения их разрушения и обеспечения безопасности.
                </Typography>
            </Box>
            <Box sx={{marginY: 3}}>
                <Typography  align={'justify'}>
                    Кроме того, мы предлагаем полный спектр топографо-геодезических услуг. Благодаря современному оборудованию и высококвалифицированным специалистам, мы можем выполнять задачи любой сложности, обеспечивая быстрые и качественные результаты.
                </Typography>
            </Box>
            <Box sx={{marginY: 3}}>
                <Typography align={'justify'}>
                    Область дистанционного зондирования и лазерного сканирования для нас также не является новой. Мы используем последние технологии для получения детальных и точных данных о местности, что позволяет клиентам получить полное представление о своем участке или объекте.
                </Typography>
            </Box>
            <Box sx={{marginY: 3}}>
                <Typography align={'justify'}>
                    ООО «НПО «Градиент» не просто предлагает своим клиентам широкий спектр услуг, но и подкрепляет свою деятельность наличием всех необходимых документов, подтверждающих качество и законность предоставляемых услуг. Все наши специалисты соответствуют высшим стандартам профессионализма и обладают необходимыми лицензиями и сертификатами. Это подтверждает нашу приверженность к законодательству и заботу о безопасности и интересах наших клиентов.
                </Typography>
            </Box>
        </Container>
    );
}

export default AboutUsPage;